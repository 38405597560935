<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <Toast />
                <h5>{{ $t('menu.ticketsLogs') }}</h5>
                <div class="p-grid">
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Calendar ref="startDateCalendar" style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="startDateModel"></Calendar>
                            <label>{{ $t('sportsbets.startDate') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Calendar style="width: 100%" dateFormat="dd.mm.yy" :showTime="true" hourFormat="24" :showIcon="true" :showButtonBar="true" v-model="endDateModel"></Calendar>
                            <label>{{ $t('sportsbets.endDate') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <span class="p-float-label">
                            <Dropdown :showClear="true" style="width: 100%" v-model="usersModel" :options="users" optionLabel="name" :filter="!this.isMobile()" />
                            <label>{{ $t('dropdown.placeholder.users') }}</label>
                        </span>
                    </div>
                    <div class="p-col-12 p-md-offset-4 p-md-2 p-text-right"><Button :label="$t('buttons.getData')" class="p-button-raised p-button-success" @click="applyFiltersOnly()" /></div>
                </div>
                <Fieldset :legend="$t('search.byId')" :toggleable="true" :collapsed="true" class="p-col-12 p-mb-2">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-2">
                            <span class="p-float-label">
                                <InputText style="width: 100%" type="text" v-model="ticketSerial" />
                                <label>{{ $t('bets.voucherSerial') }}</label>
                            </span>
                        </div>
                        <div class="p-col-12 p-md-2 p-text-right"><Button :label="$t('buttons.searchData')" class="p-button-raised p-button-warning" @click="applySerialOnly()" /></div>
                    </div>
                </Fieldset>

                <DataTable :lazy="true" :totalRecords="totalItems" :value="items" :paginator="true" :rows="perPage" :loading="loading" ref="datatable" responsiveLayout="scroll" :paginatorBottom="true" @page="onPage($event)" @sort="onSort($event)">
                    <template #header> </template>
                    <Column
                        ><template #body="slotProps">
                            <span class="indexColumn">{{ (this.crtPage - 1) * this.perPage + slotProps.index + 1 }}</span>
                        </template>
                    </Column>
                    <Column field="ticketSerial" :header="$t('bets.ticketSerial')" :sortable="true"></Column>
                    <Column field="date" :header="$t('bets.date')" :sortable="true">
                        <template #body="{ data }"> {{ formatCSDateAndTime(data.date) }} </template>
                    </Column>
                    <Column field="user" :header="$t('bets.user')" :sortable="true"></Column>
                    <Column field="changes" :header="$t('bets.action')">
                        <template #body="{ data }">{{ data.changes != null && data.changes.action != null ? data.changes.action : data.changes }}</template>
                    </Column>
                    <Column field="changes" :header="$t('bets.details')">
                        <template #body="{ data }">{{ data.changes != null ? data.changes.details : '' }}</template>
                    </Column>
                    <Column field="changes" :header="$t('bets.changes')">
                        <template #body="{ data }">{{ getChanges(data) }}</template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import { BoApi } from '../../service/BoApi';
import { uiSettings } from '../../settings/uiSettings';
export default {
    name: 'vouchers',
    data() {
        return {
            tableHeight: uiSettings.tableHeight,
            shopsDictionary: [],
            startDateModel: null,
            endDateModel: null,
            BoApi: new BoApi(),
            users: [],
            items: [],
            usersModel: { name: 'All', id: '' },
            sort: 'date|desc',
            crtPage: 0,
            perPage: uiSettings.tableRows,
            loading: true,
            totalItems: 0,
            ticketSerial: '',
        };
    },
    watch: {
        usersModel() {
            if (!this.notNullOrEmptyObject(this.usersModel)) {
                this.userssModel = { name: 'All', id: '' };
            }
        },
    },
    mounted() {
        let crtDate = new Date();
        let year = crtDate.getFullYear();
        let month = crtDate.getMonth() + 1;
        if (month <= 9) {
            month = '0' + month;
        }
        let day = crtDate.getDate();
        if (day <= 9) {
            day = '0' + day;
        }
        var startDateString = year + '-' + month + '-01T00:00';
        var endDateString = year + '-' + month + '-' + day + 'T23:59';
        this.startDateModel = new Date(startDateString);
        this.endDateModel = new Date(endDateString);
        this.getCashierUsers();
        this.getShops();
        this.readItems();
    },
    methods: {
        getShops() {
            this.BoApi.getShops('')
                .then((shopsResponse) => {
                    for (var i = 0; i < shopsResponse.data.length; i++) {
                        this.shopsDictionary[shopsResponse.data[i]._id] = shopsResponse.data[i].name;
                    }
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
        getCashierUsers() {
            this.BoApi.getUsers()
                .then((userResponse) => {
                    let usersDdlValues = [];
                    var sortedusers = userResponse.data.sort((a, b) => (a.username > b.username ? 1 : -1));
                    for (var i = 0; i < sortedusers.length; i++) {
                        let userItem = {
                            name: sortedusers[i].username,
                            id: sortedusers[i].username,
                        };
                        usersDdlValues.push(userItem);
                    }
                    this.users = usersDdlValues;
                    this.users.unshift(this.usersModel);
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
        applyFiltersOnly() {
            this.ticketSerial = '';
            this.readItems();
        },
        applySerialOnly() {
            this.userssModel = { name: 'All', id: '' };
            this.readItems();
        },
        onPage(event) {
            this.readItems(event.page + 1);
        },
        onSort(event) {
            let sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.sort = event.sortField + '|' + sortOrder;
            this.readItems();
        },
        readItems(page = 1) {
            this.crtPage = page;
            this.loading = true;

            const params = {
                per_page: this.perPage,
                page: page,
                sort: this.sort,
                startDate: this.formatApiDate(this.startDateModel),
                endDate: this.formatApiDate(this.endDateModel),
                user: this.usersModel.id,
                ticketSerial: this.ticketSerial,
            };
            this.BoApi.getTicketsLogs(params)
                .then((response) => {
                    if (response.data.data) {
                        this.items = response.data.data;
                    }
                    this.totalItems = response.data.total;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    this.checkForUnauthorizedError(error);
                });
        },
        getChanges(data) {
            var date = data.changes;
            var returnString = '';
            if (date.action != null && date.action === 'pay') {
                returnString = 'Shop:' + this.shopsDictionary[date.shopId] + ';Winner:' + date.winnerName;
            }
            if (date.action != null && date.action === 'newStatus') {
                returnString = 'NewStatus:' + date.newStatus;
            }
            return returnString;
        },
    },
};
</script>
